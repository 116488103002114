import { NgModule } from '@angular/core';

import { ComponentsModule } from './components';
import { DialogsModule } from './dialogs';
import { PresentsModule } from './presents';

@NgModule({
  exports: [
    ComponentsModule,
    DialogsModule,
    PresentsModule
  ]
})
export class SharedModule { }
