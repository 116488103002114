import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';

export interface Explanation {
  avatar: string;
  removeAvatarRoundness?: boolean;
  title: string;
  subtitle?: string;

  paragraphs: string[];
  actions?: {
    id: string;
    title: string;
    icon?: string;
  }[];
}

@Component({
  selector: 'wd-card-explanations',
  templateUrl: './card-explanations.component.html',
  styleUrls: ['./card-explanations.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardExplanationsComponent implements OnInit {

  @Input()
  cards: Explanation[];

  @Input()
  itemsPerRow = 2;

  @Output()
  actionClicked = new EventEmitter<string>();

  constructor() { }

  ngOnInit() {
  }

}
