<div fxLayout="column">
  <div fxFlex="64px" fxLayout="row" fxLayoutAlign="space-between center">
    <button mat-raised-button color="primary" (click)="resetTransform()" *ngIf="isTransformed"><mat-icon>photo_size_select_large</mat-icon> Ansicht zurücksetzen</button>
    <span></span>
    <div>
      <button mat-mini-fab color="primary" *ngIf="showDownloader" [disabled]="downloadInProgress" (click)="imageInstance.downloadCurrentImage()"><mat-icon>cloud_download</mat-icon></button>
      <button mat-mini-fab color="primary" cdkFocusInitial mat-dialog-close><mat-icon>close</mat-icon></button>
    </div>
  </div>
  <div fxFlex="1 1 calc(100% - 184px)" fxLayout="row" fxLayoutAlign="space-between center">
    <div fxFlex="0 1 auto" fxLayout="column" fxLayoutAlign="center center" fxHide.lt-md>
      <button mat-mini-fab color="primary" [disabled]="currentIndex === 0" (click)="previous()"><mat-icon>keyboard_arrow_left</mat-icon></button>
    </div>
    <div fxFlex="grow" class="image-container" fxLayoutAlign="center center"
      (swipeleft)="next()"
      (swiperight)="previous()"
      (panstart)="onMoveStart($event)"
      (panmove)="onMove($event)"
      (pinchstart)="onPinchStart($event)"
      (pinchmove)="onPinch($event)"
    >
    <ng-template [wdImage]="currentImageId" [wdImageLoading]="loading" [wdImageSize]="imageSize.FULL" #imageInstance="wdImage" (imageDownload)="downloadInProgress = $event" (imageDownloadError)="showDownloadError()">
      <img [style.transform]="getTransformValue()" class="full-image">
    </ng-template>
    </div>
    <div fxFlex="0 1 auto" fxLayout="column" fxLayoutAlign="center center" fxHide.lt-md>
      <button mat-mini-fab color="primary" [disabled]="currentIndex === imageIds.length - 1" (click)="next()"><mat-icon>keyboard_arrow_right</mat-icon></button>
    </div>
  </div>

  <div class="image-stripe" fxFlex="120px" fxLayout="row" fxLayoutAlign="stretch stretch">
    <cdk-virtual-scroll-viewport orientation="horizontal" itemSize="140">
      <div class="stripe-image" *cdkVirtualFor="let item of imageIds; let i = index" [class.active]="i === currentIndex">
        <div class="image" *wdImage="item; loading stripeLoading; else stripeLoading" (click)="selectIndex(i)"></div>
      </div>
    </cdk-virtual-scroll-viewport>
  </div>

  <ng-template #loading>
      <div class="loader" fxLayout="column" fxLayoutAlign="center center">
        <mat-progress-spinner color="primary" mode="indeterminate" [diameter]="80" [strokeWidth]="3">
        </mat-progress-spinner>
      </div>
    </ng-template>

    <ng-template #stripeLoading>
      <wd-image-placeholder></wd-image-placeholder>
    </ng-template>
</div>
