import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { Todo } from '@wd/model';

@Component({
  selector: 'wd-todo-status',
  template: `
    <wd-status *ngIf="todo"
               [icon]="iconOverride"
               [inverse]="inverse"
               [status]="todo.status"
               [text]="todo.description"
               [successVal]="true"
               [errorVal]="false"
               [unknownVal]="-10"></wd-status>
`,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TodoStatusComponent implements OnInit {

  @Input()
  todo: Todo;

  @Input()
  inverse: boolean;

  @Input()
  iconOverride: string;

  constructor() { }

  ngOnInit() {
  }

}
