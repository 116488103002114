import { Component, OnInit, Inject, ViewEncapsulation, ChangeDetectionStrategy } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'wd-youtube-dialog',
  templateUrl: './youtube-dialog.component.html',
  styleUrls: ['./youtube-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class YoutubeDialogComponent implements OnInit {

  currentVideoId: string | undefined = undefined;

  constructor(@Inject(MAT_DIALOG_DATA) config: string) {
    this.currentVideoId = config;
  }

  ngOnInit() {
  }
  startVideo(player: any) {
    player.playVideo();
  }

}
