import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Present } from '@wd/model';

@Component({
  selector: 'wd-present-grid',
  templateUrl: './present-grid.component.html',
  styleUrls: ['./present-grid.component.scss']
})
export class PresentGridComponent implements OnInit {

  @Input()
  presents: Present[];

  @Input()
  invitationId: number;

  @Input()
  allowModifications = false;

  @Output()
  takePresent = new EventEmitter<Present>();

  @Output()
  givePresent = new EventEmitter<Present>();

  @Output()
  updateGiftValue = new EventEmitter<{ present: Present, value: number }>();

  constructor() { }

  ngOnInit() { }

  trackPresent(index, present: Present) {
    return present.id;
  }

}
