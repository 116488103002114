<div fxLayout="row" fxLayoutAlign="space-between">
  <h2 mat-dialog-title>Nicht verzagen!</h2> <button mat-icon-button mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>
<mat-dialog-content>
  <p>Falls du Hilfe brauchst oder irgendwelche Fragen hast, kannst du uns gerne kontaktieren!</p>
  <p>Laura: <a href="tel:+4915119149176">015119149176</a>
    <br />Ben: <a href="tel:+4915254604240">015254604240</a>
  </p>
</mat-dialog-content>