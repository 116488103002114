import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';

import { environment } from '@environment';
import { Image } from '@wd/model';

export interface ApiResponse<T = any> {
  data: T;
  success: boolean;
  message?: string;
}

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  token: string;

  constructor(private httpClient: HttpClient) { }

    private static safeJoin(path1: string, path2: string): string {
      const part1 = path1.substring(0, path1.length - (path1.endsWith('/') ? 1 : 0));
      const part2 = path2.substring(path2.startsWith('/') ? 1 : 0);

      return part1 + '/' + part2;
    }

    private static getUrl(path: string) {
      return this.safeJoin(environment.apiPath, path);
    }

  /**
   * Performs a get request against the backend.
   *
   * @param path has to start with /
   * @param headers pass undefined to use default headers
   */
  performGet<T>(path: string, headers?: HttpHeaders, params?: HttpParams): Observable<T> {
    if (headers === undefined) {
      headers = this.getHeaders();
    }

    return this.httpClient.get<ApiResponse<T>>(ApiService.getUrl(path), { headers: headers, params: params })
    .pipe(
      map(x => x.data)
    );
  }

  /**
   * Performs a post request against the backend.
   *
   * @param path has to start with /
   * @param body the request body
   * @param headers pass undefined to use default headers
   */
  performPost<T>(path: string, body: any, headers?: HttpHeaders): Observable<T> {
    if (headers === undefined) {
      headers = this.getHeaders();
    }

    return this.httpClient.post<ApiResponse<T>>(ApiService.getUrl(path), body, { headers: headers })
    .pipe(
      tap(x => {
        if (x && x.message) {
          console.log('ERROR: ' + x.message);
        }
      }),
      map(x => x.data)
    );
  }

  /**
   * Performs a put request against the backend.
   *
   * @param path has to start with /
   * @param body the request body
   * @param headers pass undefined to use default headers
   */
  performPut(path: string, body: any, headers?: HttpHeaders): Observable<boolean> {
    if (headers === undefined) {
      headers = this.getHeaders();
    }

    return this.httpClient.put<ApiResponse>(ApiService.getUrl(path), body, { headers: headers })
    .pipe(
      map(x => x.success)
    );
  }

  /**
   * Performs a delete request against the backend.
   *
   * @param path has to start with /
   * @param body the request body
   * @param headers pass undefined to use default headers
   */
  performDelete(path: string, headers?: HttpHeaders): Observable<boolean> {
    if (headers === undefined) {
      headers = this.getHeaders();
    }

    return this.httpClient.delete<ApiResponse>(ApiService.getUrl(path), { headers: headers })
    .pipe(
        map(x => x.success)
    );
  }

  downloadImage(imageId: number, sizeQuery: string): Observable<Blob | null> {
    const headers = this.getHeaders().set('Accept', 'application/octet-stream');
    let params = new HttpParams();
    params = params.set('id', '' + imageId);
    params = params.set('size', sizeQuery);

    return this.httpClient.get(ApiService.getUrl('common/images/download'),
      {
        headers: headers,
        params: params,
        responseType: 'blob',
        observe: 'response'
      }
    ).pipe(
      catchError(() => of(null)),
      map(response => response ? response.body : null)
    );
  }

  uploadImage(image: Blob): Observable<Image> {
    const headers = this.getHeaders().delete('Content-Type');
    const formData = new FormData();
    formData.append('file', image);

    return this.performPost('/common/images/upload', formData, headers);
  }

  uploadGalleryImage(file: File, galleryId: number): Observable<Image> {
    const headers = this.getHeaders().delete('Content-Type');
    const formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('gallery_id', galleryId + '');

    return this.performPost('/admin/images/upload', formData, headers);
  }

  private getHeaders(): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    });
  }
}
