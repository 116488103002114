import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  HelpDialogComponent,
  SimpleSnackBarData,
  SimpleSnackBarComponent,
  SnackBarActionType,
  SnackBarType,
  ImageDialogComponent,
  ImageDialogConfig,
  YoutubeDialogComponent
} from '../shared/dialogs';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  token: string;

  constructor(private matDialog: MatDialog, private matSnackBar: MatSnackBar) { }

  showHelpDialog() {
      this.matDialog.open(HelpDialogComponent);
  }

  showYoutubeDialog(videoId: string) {
    this.matDialog.open(YoutubeDialogComponent, {
      backdropClass: 'wd-image-dialog-backdrop',
      panelClass: 'wd-image-dialog',
      data: videoId,
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%'
    });
  }


  showImageDialog(imageIds: number | number[], showDownloader: boolean = false, selectedImageId?: number) {
    this.matDialog.open(ImageDialogComponent, {
      backdropClass: 'wd-image-dialog-backdrop',
      panelClass: 'wd-image-dialog',
      data: <ImageDialogConfig>{
        imageIds: Array.isArray(imageIds) ? imageIds : [ imageIds ],
        selectedImageId: selectedImageId,
        displayDownloadErrorMessage: this.showImageDownloadErrorNotification.bind(this),
        showDownloader: showDownloader
      },
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%'
    });
  }

  showSnackbar(snackbarConfiguration: SimpleSnackBarData) {
    return this.matSnackBar.openFromComponent(SimpleSnackBarComponent, {
      data: snackbarConfiguration,
      panelClass: SimpleSnackBarComponent.getActionClass(snackbarConfiguration.type),
      duration: snackbarConfiguration.autoCloseDuration || 0
    });
  }

  showRequestStartNotification() {
    return this.showSnackbar({
      actionType: SnackBarActionType.NONE,
      message: 'Daten werden gespeichert',
      type: SnackBarType.DEFAULT,
    });
  }

  showRequestSuccessNotification() {
    return this.showSnackbar({
      actionType: SnackBarActionType.NONE,
      autoCloseDuration: 3000,
      message: 'Speichern erfolgreich',
      type: SnackBarType.SUCCESS
    });
  }

  showRequestErrorNotification() {
    return this.showSnackbar({
      action: 'OK',
      actionType: SnackBarActionType.TEXT,
      message: 'Fehler beim Speichern! Bitte lade die Seite neu',
      type: SnackBarType.ERROR
    });
  }

  showLoadErrorNotification() {
    return this.showSnackbar({
      action: 'OK',
      actionType: SnackBarActionType.TEXT,
      message: 'Fehler beim Laden der Daten! Bitte lade die Seite neu',
      type: SnackBarType.ERROR
    });
  }

  showImageDownloadErrorNotification() {
    return this.showSnackbar({
      action: 'OK',
      actionType: SnackBarActionType.TEXT,
      message: 'Fehler beim Herunterladen des Bildes! Nutze alternativ die Speichern-Funktion deines Browsers',
      type: SnackBarType.ERROR
    });
  }
}
