<wd-responsive-columns [trackByFn]="trackPresent" [elements]="presents" [elementTemplate]="presentTemplate"></wd-responsive-columns>


<ng-template #presentTemplate let-present>
  <wd-present-card [present]="present" [invitationId]="invitationId"
                   [allowModifications]="allowModifications"
                   (takeButtonClicked)="takePresent.emit(present)"
                   (giveButtonClicked)="givePresent.emit(present)"
                   (updateGiftValue)="updateGiftValue.emit({ present: present, value: $event })"></wd-present-card>
</ng-template>
