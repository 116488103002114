<wd-responsive-columns [elementTemplate]="cardTemplate" [elements]="cards" [maxElementsPerRow]="itemsPerRow"></wd-responsive-columns>

<ng-template #cardTemplate let-card>
  <mat-card>
      <mat-card-header [class.single-row]="!card.subtitle">
        <img *ngIf="card.avatar" [class.square]="card.removeAvatarRoundness" mat-card-avatar [src]="card.avatar" />
        <mat-card-title>{{ card.title }}</mat-card-title>
        <mat-card-subtitle *ngIf="card.subtitle">{{ card.subtitle }}</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <p *ngFor="let p of card.paragraphs">{{ p }}</p>
      </mat-card-content>
      <ng-container *ngIf="card.actions">
        <mat-divider></mat-divider>
        <mat-card-actions>
          <button *ngFor="let action of card.actions" mat-button (click)="actionClicked.emit(action.id)"><mat-icon *ngIf="action.icon">{{ action.icon }}</mat-icon>{{ action.title }}</button>
        </mat-card-actions>
      </ng-container>
    </mat-card>
</ng-template>
