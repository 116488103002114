import { Component, OnInit, Input, EventEmitter, OnDestroy, Output, ElementRef, OnChanges, SimpleChanges } from '@angular/core';
import { ResizeSensor } from 'css-element-queries';
import { Subject } from 'rxjs';
import { takeUntil, startWith, throttleTime } from 'rxjs/operators';

@Component({
  selector: 'wd-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  mobileMenu = false;

  @Input()
  showAdditionalMobileMenuItems = false;

  @Input()
  mobileMenuItemsAdditional = [];

  @Input()
  menuItems = [];

  @Output()
  itemClicked = new EventEmitter();

  exceedingItemIndex = -1;

  itemBreakpoints: number[] = [];

  resizeSensor: ResizeSensor;
  resized = new Subject();
  private _unsubscribeAll = new Subject();

  constructor(private elementRef: ElementRef<HTMLElement>) { }

  ngOnInit() {
    this.resizeSensor = new ResizeSensor(this.elementRef.nativeElement, (() => { this.resized.next(this); }).bind(this));

    this.resized.pipe(
      startWith(null),
      throttleTime(100),
      takeUntil(this._unsubscribeAll)
    ).subscribe(() => {
      const maxWidth = this.elementRef.nativeElement.clientWidth;

      for (let x = 0; x < this.itemBreakpoints.length; x++) {
        if (this.itemBreakpoints[x] > maxWidth) {
          this.exceedingItemIndex = x;
          return;
        }
      }

      this.exceedingItemIndex = this.menuItems.length;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Whenever the menuItems change, calculate all breakpoints for hiding/showing elements
    if (changes['menuItems']) {
        // Wait for the browser to create all dom elements for the menu items
      setTimeout(() => {
        const itemWidths: number[] = [];
        const breakpoints: number[] = [];
        this.elementRef.nativeElement.querySelectorAll('.menu-browser-item').forEach((item: HTMLButtonElement) => {
          itemWidths.push(this.calculateWidth(item));
        });

        const toggler: HTMLElement = this.elementRef.nativeElement.querySelector('.menu-browser-item-toggler');
        const togglerWidth = toggler ? this.calculateWidth(toggler) : 0;

        let summedWidth = 0;
        for (let x = 0; x < itemWidths.length; x++) {
          summedWidth += itemWidths[x];
          breakpoints[x] = summedWidth + Math.min(x < itemWidths.length - 1 ? itemWidths[x + 1] : 0, togglerWidth);
        }

        this.itemBreakpoints = breakpoints;
      }, 0);
    }
  }

  ngOnDestroy() {
    this.resizeSensor.detach();
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  emitItemClick(menuItem: any) {
    this.itemClicked.emit(menuItem);
  }

  calculateWidth(el: HTMLElement): number {
    const style = window.getComputedStyle ? getComputedStyle(el, null) : (<any>el).currentStyle;

    const marginLeft = parseInt(style.marginLeft, 10) || 0;
    const marginRight = parseInt(style.marginRight, 10) || 0;

    const oldStyle = el.style.display;
    el.style.display = 'inline-block';
    const width = el.clientWidth;
    el.style.display = oldStyle;

    return marginLeft + width + marginRight;
  }

}
