import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * This service returns the current loading status of all <img>,
 * that make use of the image-loader directive.
 * 
 * It returns loading=true, if any loading process is going on
 * for all running instances.
 */
@Injectable({
  providedIn: 'root'
})
export class ImageLoaderService {

  private counter$ = new BehaviorSubject<number>(0);

  public get loading(): Observable<boolean> {
    return this.counter$.pipe(
      map(x => x > 0)
    );
  }

  constructor() { }

  loadingStarted() {
    this.counter$.next(this.counter$.getValue() + 1);
  }

  loadingFinished() {
    this.counter$.next(this.counter$.getValue() - 1);
  }
}
