import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { AuthState } from '@wd/core/state';

@Injectable({
  providedIn: 'root'
})
export class LoggedOutGuard implements CanActivate {
  constructor(private store: Store, private router: Router) {}
​
  canActivate() {
    const token = this.store.selectSnapshot(AuthState.token);
    const isLoggedOut = !token;

    if (!isLoggedOut) {
      this.router.navigate(['/']);
    }

    return isLoggedOut;
  }
}
