import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { InvitationState } from '@wd/core/state';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class EmailRequiredGuard implements CanActivate {
  constructor(private store: Store, private router: Router, private matSnackbar: MatSnackBar) {}
​
  canActivate() {
    const requiresEmail = this.store.selectSnapshot(InvitationState.requiresEmail);

    if (requiresEmail) {
      this.matSnackbar.open('Deine E-Mail-Adresse fehlt noch!', 'Verstanden')
      this.router.navigate(['/account']);
    }

    return !requiresEmail;
  }
}
