import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'column'
})
export class ColumnPipe implements PipeTransform {

  transform(value: any[], column: number, columns: number): any {
    if (value) {
      const filtered = value.filter((_, index) => (index - column + 1) % columns === 0);
      return filtered;
    }

    return null;
  }

}
