import { Injectable } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Actions } from '@ngxs/store';

@Injectable({
  providedIn: 'root'
})
export class NgxsEventsService {

  private counter = 0;
  private subscriptions: Map<number, Subscription> = new Map();

  constructor(private actions: Actions) { }

  /**
   * Adds a new event selector to NGXS's actions.
   *
   * @param selector The selector function, e.g. ofActionSuccessful(yourDesiredActionClass)
   * @param callbackFn The function, that will be executed everytime the selector matched an action
   * @return the subscription ID to cancel the subscription using cancelListener();
   */
  listenForEvent(selector: (o: Observable<any>) => Observable<any>, callbackFn: () => void): number {
    const id = this.counter;
    const subcription = this.actions.pipe(
      selector
    ).subscribe(
      () => callbackFn()
    );

    this.counter++;

    this.subscriptions.set(id, subcription);

    return id;
  }

  cancelListener(id: number) {
    if (this.subscriptions.has(id) && !this.subscriptions.get(id).closed) {
      this.subscriptions.get(id).unsubscribe();
      this.subscriptions.delete(id);
    } else {
      console.warn('Subscription with id ' + id + ' non-existent or already canceled!');
    }
  }


}
