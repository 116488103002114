<div class="background"></div>

<mat-toolbar color="primary" fxLayoutAlign="center center" fxLayoutAlign.lt-md="left center">
  <wd-menu [menuItems]="topMenuItems" [mobileMenu]="true" [mobileMenuItemsAdditional]="footerMenuItems" [showAdditionalMobileMenuItems]="showAdditionalMenuItems" (itemClicked)="handleMenuClick($event)"></wd-menu>
</mat-toolbar>

<router-outlet></router-outlet>

<mat-toolbar class="toolbar-footer" color="primary" fxLayoutAlign="center center">
    <wd-menu [menuItems]="footerMenuItems" (itemClicked)="handleMenuClick($event)"></wd-menu>
</mat-toolbar>
