import { Present } from '@wd/model';

export function isOwningPresent(present: Present, invitationId: number): boolean {
  return (
    present.split_price && present.present_gifts && present.present_gifts.some(x => x.invitation_id === invitationId)
  ) || present.invitation_id === invitationId;
}

export function getAmountOnPresent(present: Present, invitationId: number): number {
  if (present.split_price && present.present_gifts) {
    const gift = present.present_gifts.find(x => x.invitation_id === invitationId);
    if (gift) {
      return gift.amount;
    }
  } else {
    if (present.invitation_id === invitationId) {
      return present.price;
    }
  }

  return 0;
}
