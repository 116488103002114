import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Store } from '@ngxs/store';

import { Observable } from 'rxjs';
import { AuthState } from '@wd/core/state';
import { environment } from '@environment';

export type StateSelector = any;

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  private selectors = new Map<string, StateSelector>();
  private sortedKeysBySpecifitivity: string[];

  constructor(public store: Store) {
    this.registerTokenSelectorForRoute('/', AuthState.token);
  }

  public registerTokenSelectorForRoute(routePrefix: string, tokenSelector: StateSelector) {
    this.selectors.set(routePrefix, tokenSelector);
    this.rebuildOrder();
  }

  public unregisterTokenSelector(routePrefix: string) {
    if (this.selectors.has(routePrefix)) {
      this.selectors.delete(routePrefix);
    }

    this.rebuildOrder();
  }

  private rebuildOrder() {
    this.sortedKeysBySpecifitivity = [];
    this.selectors.forEach((_, key: string) => {
      this.sortedKeysBySpecifitivity.push(key);
    });

    this.sortedKeysBySpecifitivity.sort((a, b) => {
      const aSlashes = a.split('/').length;
      const bSlashes = b.split('/').length;

      if (aSlashes === bSlashes) {
        return b.length - a.length;
      } else {
        return bSlashes - aSlashes;
      }
    });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let selector: StateSelector;
    const remaining = request.url.replace(environment.apiPath, '');
    for (let x = 0; x < this.sortedKeysBySpecifitivity.length; x++) {
      if (remaining.startsWith(this.sortedKeysBySpecifitivity[x])) {
        selector = this.selectors.get(this.sortedKeysBySpecifitivity[x]);
        break;
      }
    }

    let token: string;

    if (selector) {
      token = this.store.selectSnapshot(selector);
    }

    if (token) {
        request = request.clone({
        setHeaders: {
            Authorization: `Bearer ${token}`
        }
        });
    }

    return next.handle(request);
  }
}
