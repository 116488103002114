import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { ApiService } from './api.service';
import { Invitation, Setting } from '@wd/model';

@Injectable({
  providedIn: 'root'
})
export class InvitationService {
  constructor(
    private apiService: ApiService) { }

  login(payload: { code: string }): Observable<{ token: string, invitation: Invitation }> {
    return this.apiService.performPost('/guest/invitations/token', payload);
  }

  info(): Observable<{ invitation: Invitation, settings: Setting[] }> {
    return this.apiService.performGet<{ invitation: Invitation, settings: Setting[] }>('/guest/invitations/info').pipe();
  }

  updateNights(nights: number): Observable<Invitation> {
    return this.apiService.performPost('/guest/invitations/update_nights', { nights });
  }

  updateBookingConfirmation(confirmed: boolean): Observable<Invitation> {
    return this.apiService.performPost('/guest/invitations/update_booking_confirmation', { confirmed });
  }

  updateShuttleDecision(shuttleStart: number, shuttleEnd: number): Observable<Invitation> {
    return this.apiService.performPost('/guest/invitations/update_shuttle', { shuttle_start: shuttleStart, shuttle_end: shuttleEnd });
  }

  updateBreakfastDecision(decision: -1 | 0 | 1 | 2): Observable<Invitation> {
    return this.apiService.performPost('/guest/invitations/update_breakfast', { breakfast: decision });
  }

  updateFoodContribution(food_contribution: -1 | 0 | 1 | 2): Observable<Invitation> {
    return this.apiService.performPost('/guest/invitations/update_food_contribution', { food_contribution });
  }

  logout(): Observable<boolean> {
    return of(true);
  }
}
