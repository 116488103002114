import { Injectable } from '@angular/core';
import { State, Selector, StateContext, Action } from '@ngxs/store';
import { InvitationService } from '@wd/core/service';
import { tap, finalize } from 'rxjs/operators';
import { MenuButtonClicked } from './page-layout.state';

export class AuthStateModel {
  token?: string;
  loading: boolean;
}
​
export class Login {
  static readonly type = '[Auth] Login';
  constructor(public payload: { code: string }) {}
}
export class Logout {
  static readonly type = '[Auth] Logout';
}

@State<AuthStateModel>({
  name: 'auth',
  defaults: {
    loading: false
  }
})
@Injectable()
export class AuthState {
  ​
  @Selector([ AuthState ])
  static token(state: AuthStateModel) { return state.token; }

  @Selector([ AuthState ])
  static loading(state: AuthStateModel) { return state.loading; }
​
  constructor(private invitationService: InvitationService) {}
  ​
  @Action(Login)
  login({ patchState }: StateContext<AuthStateModel>, loginAction: Login) {
    patchState({ loading: true });
    return this.invitationService.login(loginAction.payload).pipe(
      tap((result) => {
        patchState({
          token: result.token
        });
      }),
      finalize(() =>
        patchState({ loading: false })
      )
    );
  }

  @Action(MenuButtonClicked)
  handleMenuLogout(ctx: StateContext<AuthStateModel>, action: MenuButtonClicked) {
    if (action.id === 'logout') {
      ctx.dispatch(new Logout());
    }
  }
​
  @Action(Logout)
  logout({ setState, getState }: StateContext<AuthStateModel>) {
    return this.invitationService.logout().pipe(tap(() => {
      setState({loading: false});
    }));
  }
​
}
