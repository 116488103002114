export enum SnackBarType {
  DEFAULT,
  SUCCESS,
  ERROR
}

export enum SnackBarActionType {
  TEXT,
  ICON,
  NONE
}

export interface SimpleSnackBarData {

  /**
   * A static message that will be displayed as the main text
   */
  message?: string;

  /**
   * A static action text that will be displayed on the action button.
   * Will be wrapped inside a <mat-icon> if actionType is ICON.
   */
  action?: string;

  /**
   * The type of action that is presented to the user. Falls back to SnackBarActionType.TEXT.
   */
  actionType?: SnackBarActionType;

  /**
   * The type of snackbar that is presented to the user. Falls back to SnackBarType.DEFAULT.
   */
  type?: SnackBarType;

  /**
   * A duration after which the snack bar will be closed automatically.
   */
  autoCloseDuration?: number;
}
