import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'wd-guest-status',
  templateUrl: './guest-status.component.html',
  styleUrls: ['./guest-status.component.scss']
})
export class GuestStatusComponent implements OnInit {

  @Input()
  status = 0;

  @Input()
  text = null;

  constructor() { }

  ngOnInit() {
  }

}
