
import { Injectable, NgZone } from '@angular/core';
import { State, Selector, StateContext, Action } from '@ngxs/store';

import { Invitation } from '@wd/model';
import { InvitationService, DialogService } from '@wd/core/service';

import { of, Observable } from 'rxjs';
import { tap, catchError, finalize } from 'rxjs/operators';

import { produce } from 'immer';

import { UpdateSettings, SettingsState } from './settings.state';
import { Logout } from './auth.state';

export class InvitationStateModel {
  invitation?: Invitation;
  loading: boolean;
  saving: boolean;
}

export enum FoodContribution {
  SWEET = 2,
  SPICY = 1,
  UNKNOWN = 0,
  NOTHING = -1
}

export enum BreakfastDecision {
  HIDDEN = 2,
  ATTENDING = 1,
  UNKNOWN = 0,
  NOT_ATTENDING = -1
}

export class LoadInvitation {
  static readonly type = '[Invitation] Load invitation';
  constructor() { }
}

export class UpdateInvitationNights {
  static readonly type = '[Invitation] Update nights';
  constructor(public nights: number) { }
}

export class UpdateBookingConfirmation {
  static readonly type = '[Invitation] Booking confirmation';
  constructor(public confirmed: boolean) { }
}

export class UpdateBreakfastDecision {
  static readonly type = '[Invitation] Breakfast decision';
  constructor(public decision: BreakfastDecision) { }
}

export class UpdateFoodContribution {
  static readonly type = '[Invitation] Food contribution';
  constructor(public contribution: FoodContribution) { }
}

export class UpdateShuttleUsage {
  static readonly type = '[Invitation] Shuttle usage';
  constructor(public shuttleStart: number, public shuttleEnd: number) { }
}

@State<InvitationStateModel>({
  name: 'invitation',
  defaults: {
    saving: false,
    loading: false
  }
})
@Injectable()
export class InvitationState {
  @Selector([InvitationState])
  static loading(state: InvitationStateModel): boolean {
    return state.loading;
  }

  @Selector([InvitationState])
  static saving(state: InvitationStateModel): boolean {
    return state.saving;
  }

  @Selector([InvitationState])
  static invitation(state: InvitationStateModel): Invitation {
    return state.invitation;
  }

  @Selector([InvitationState])
  static invitationId(state: InvitationStateModel): number {
    return state.invitation ? state.invitation.id : null;
  }

  @Selector([InvitationState])
  static nights(state: InvitationStateModel): number {
    return state.invitation ? state.invitation.nights : 0;
  }

  @Selector([InvitationState])
  static bookingConfirmed(state: InvitationStateModel): boolean {
    return state.invitation ? state.invitation.nights_confirmed : false;
  }

  @Selector([InvitationState])
  static shuttleStart(state: InvitationStateModel): number {
    return state.invitation ? state.invitation.shuttle_start : 0;
  }

  @Selector([InvitationState])
  static shuttleEnd(state: InvitationStateModel): number {
    return state.invitation ? state.invitation.shuttle_end : 0;
  }

  @Selector([InvitationState])
  static requiresEmail(state: InvitationStateModel) {
    return state && state.invitation && !state.invitation.email;
  }

  @Selector([InvitationState])
  static breakfast(state: InvitationStateModel): BreakfastDecision {
    return state.invitation.breakfast;
  }

  @Selector([InvitationState])
  static foodContribution(state: InvitationStateModel): FoodContribution {
    return state.invitation.food_contribution;
  }

  @Selector([InvitationState, SettingsState.enableHotel, SettingsState.lockHotel])
  static showHotelConfirmator(state: InvitationStateModel, enableHotel: boolean, lockHotel: boolean) {
    return enableHotel && lockHotel && state.invitation.nights >= 1;
  }

  @Selector([InvitationState, SettingsState.enableHotel, SettingsState.lockHotel])
  static showShuttleSelector(state: InvitationStateModel, enableHotel: boolean, lockHotel: boolean) {
    return InvitationState.showHotelConfirmator(state, enableHotel, lockHotel);
  }

  @Selector([InvitationState, SettingsState.enableHotel, SettingsState.lockHotel])
  static showBreakfastSelector(state: InvitationStateModel, enableHotel: boolean, lockHotel: boolean): boolean {
    return enableHotel &&
      state.invitation.nights > 0 &&
      // TODO... somehow the database column is fucked up pretty bad :(
      (<any>state.invitation.nights) !== '0';
  }

  constructor(
    private invitationService: InvitationService,
    private dialogService: DialogService,
    private ngZone: NgZone) { }


  @Action(Logout)
  Logout(ctx: StateContext<InvitationStateModel>) {
    ctx.patchState({ invitation: undefined });
  }

  @Action(LoadInvitation)
  loadInvitation(ctx: StateContext<InvitationStateModel>, loadAction: LoadInvitation) {
    ctx.patchState({ loading: true });

    return this.invitationService.info().pipe(
      tap((result) => {
        ctx.patchState({
          invitation: result.invitation,
          loading: false
        });
        ctx.dispatch(new UpdateSettings(result.settings));
      })
    );
  }

  @Action(UpdateInvitationNights)
  updateNights(ctx: StateContext<InvitationStateModel>, nightsAction: UpdateInvitationNights) {
    return this.invitationUpdate(
      ctx,
      produce(ctx.getState(), draft => {
        draft.invitation.nights = nightsAction.nights;
      }),
      this.invitationService.updateNights(nightsAction.nights)
    );
  }

  @Action(UpdateBreakfastDecision)
  updateBreakfastDecision(ctx: StateContext<InvitationStateModel>, action: UpdateBreakfastDecision) {
    return this.invitationUpdate(
      ctx,
      produce(ctx.getState(), draft => {
        draft.invitation.breakfast = action.decision;
      }),
      this.invitationService.updateBreakfastDecision(action.decision)
    );
  }

  @Action(UpdateFoodContribution)
  updateFoodContribution(ctx: StateContext<InvitationStateModel>, action: UpdateFoodContribution) {
    return this.invitationUpdate(
      ctx,
      produce(ctx.getState(), draft => {
        draft.invitation.food_contribution = action.contribution;
      }),
      this.invitationService.updateFoodContribution(action.contribution)
    );
  }

  @Action(UpdateBookingConfirmation)
  updateBookingConfirmation(ctx: StateContext<InvitationStateModel>, action: UpdateBookingConfirmation) {
    return this.invitationUpdate(
      ctx,
      produce(ctx.getState(), draft => {
        draft.invitation.nights_confirmed = action.confirmed;
      }),
      this.invitationService.updateBookingConfirmation(action.confirmed)
    );
  }

  @Action(UpdateShuttleUsage, { cancelUncompleted: true })
  updateShuttleDecision(ctx: StateContext<InvitationStateModel>, action: UpdateShuttleUsage) {
    return this.invitationUpdate(
      ctx,
      produce(ctx.getState(), draft => {
        draft.invitation.shuttle_start = action.shuttleStart;
        draft.invitation.shuttle_end = action.shuttleEnd;
      }),
      this.invitationService.updateShuttleDecision(action.shuttleStart, action.shuttleEnd)
    );
  }

  private invitationUpdate(
    ctx: StateContext<InvitationStateModel>,
    patchedState: InvitationStateModel,
    baseObservable: Observable<Invitation>
  ) {
    const oldInvitation = ctx.getState().invitation;
    ctx.setState(patchedState);
    ctx.patchState({ saving: true });
    this.dialogService.showRequestStartNotification();

    return baseObservable.pipe(
      tap((result) => {
        ctx.setState(produce(ctx.getState(), draft => {
          draft.invitation = result;
        }));

        this.ngZone.run(() => {
          this.dialogService.showRequestSuccessNotification();
        });
      }),
      catchError(() => {
        this.ngZone.run(() => {
          this.dialogService.showRequestErrorNotification();
        });

        ctx.setState(produce(ctx.getState(), draft => {
          draft.invitation = oldInvitation;
        }));

        return of(null);
      }),
      finalize(() => {
        ctx.patchState({ saving: false });
      })
    );
  }

}
