import { Component, ElementRef, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, ofActionDispatched, ofActionSuccessful, Store } from '@ngxs/store';

import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { PageLoaderService } from '@wd/core';
import { Logout, Login, PageLayoutState, AuthState } from '@wd/core/state';
import { Title } from '@angular/platform-browser';
import { NgxsEventsService } from './core/service/ngxs-events.service';

@Component({
  selector: 'wd-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  private idLogin: number;
  private idLogout: number;

  lastLayout: string;
  private elementRef: HTMLElement = document.querySelector('body');
  private _unsubscribeAll = new Subject();

  constructor(
    // do NOT remove reference to page loader - otherwise spinner will be shown forever
    private pageLoaderService: PageLoaderService,
    private ngxsEvents: NgxsEventsService,
    private store: Store,
    private titleService: Title,
    private router: Router) {

  }

  ngOnInit() {
    this.idLogin = this.ngxsEvents.listenForEvent(ofActionSuccessful(Login), () => {
      this.router.navigate(['/']);
    });
    this.idLogout = this.ngxsEvents.listenForEvent(ofActionSuccessful(Logout), () => {
      this.router.navigate(['/auth']);
    });

    this.store.select(PageLayoutState.title).pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe((title) => {
      this.titleService.setTitle(title);
    });

    this.store.select(PageLayoutState.pageLayout).pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe((layout) => {
      this.updateLayout(layout);
    });
  }

  ngOnDestroy() {
    this.ngxsEvents.cancelListener(this.idLogin);
    this.ngxsEvents.cancelListener(this.idLogout);

    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  updateLayout(layout: string) {
    if (this.lastLayout) {
      this.elementRef.classList.remove(this.lastLayout);
    }

    if (layout) {
      this.elementRef.classList.add(layout);
    }

    this.lastLayout = layout;
  }
}
