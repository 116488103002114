import { Injectable } from '@angular/core';
import { Observable, Subject, of } from 'rxjs';
import { map, takeUntil, catchError, finalize, publishReplay, refCount } from 'rxjs/operators';

import { ApiService } from './api.service';
import { Image, Gallery } from '@wd/model';
import { HttpParams } from '@angular/common/http';

export enum ImageSize {
  FULL = 'f',
  THUMBNAIL = 't'
}

@Injectable({
  providedIn: 'root'
})
export class ImageService {

  private objectUrls = new Map<string, string>();
  private cachedImages$ = new Map<string, Observable<string>>();
  private cleanUp$ = new Map<string, Subject<string>>();

  constructor(private apiService: ApiService) {}

  downloadImage(imageId: number, size = ImageSize.THUMBNAIL): Observable<string> {
    const id = this.getIdentifier(imageId, size);

    if (!this.cachedImages$.has(id)) {
      this.cleanUp$.set(id, new Subject());
      this.cachedImages$.set(id, new Observable((subscriber) => {
        // Wrap the downloading procedure in a new observable
        // as the httpClient will complete immediately.
        // The completion will also trigger finalize() to be executed immediately,
        // but we want it to be executed as soon as all subscribers left or releaseImage(...) was called
        this.apiService.downloadImage(imageId, size === ImageSize.FULL ? 'full' : 'thumb').pipe(
          takeUntil(this.cleanUp$.get(id))
        ).subscribe(blob => subscriber.next(blob));
      }).pipe(
        // Complete observable as soon as it is released
        takeUntil(this.cleanUp$.get(id)),
        map((x: Blob) => this.createObjectURL(x, id)),
        catchError(() => of(null)),
        // Executed as soon as all subscribers have unsubscribed from the published observable
        // or source is completed -> clean up
        finalize(() => { this.revokeObjectURL(id); }),
        // If the request failed, simply return null. No object URL was created, so no cleanup is necessary
        publishReplay(1),
        refCount()
      ));

      // Keep one subscriber for thumbnails
      if (size === ImageSize.THUMBNAIL) {
        this.cachedImages$.get(id).subscribe();
      }
    }

    return this.cachedImages$.get(id);
  }

  presentIntroGallery(): Observable<Image[]> {
    return this.apiService.performGet('/common/images/present_intro_gallery');
  }

  galleries(): Observable<Gallery[]> {
    return this.apiService.performGet('/guest/galleries');
  }

  releaseImage(imageId: number, size = ImageSize.THUMBNAIL) {
    if (size === ImageSize.THUMBNAIL) {
      // Forget about the force release for thumbnails... leads to too many reloads between page transitions :|
      return;
    }

    const id = this.getIdentifier(imageId, size);

    if (this.cachedImages$.get(id)) {
      this.cleanUp$.get(id).next();
      this.cleanUp$.get(id).complete();

      this.cleanUp$.delete(id);
      this.cachedImages$.delete(id);
    }
  }

  meta(imageId: number) {
    const queryParams = new HttpParams().append('id', imageId + '');
    return this.apiService.performGet('/common/images/meta', undefined, queryParams);
  }

  uploadImage(blob: Blob): Observable<Image> {
    return this.apiService.uploadImage(blob);
  }

  uploadGalleryImage(file: File, galleryId: number): Observable<Image> {
    return this.apiService.uploadGalleryImage(file, galleryId);
  }

  /**
   * Creates a new object URL and stores it in the interal object URL map.
   *
   * @param blob The image blob
   * @param imageId The image's id, to which the blob image belongs
   */
  private createObjectURL(blob: Blob, imageId: string): string {
    if (this.objectUrls.has(imageId)) {
      throw new Error('Revoke object URL for imageID ' + imageId + ' before creating a new one!');
    }
    const url = URL.createObjectURL(blob);
    this.objectUrls.set(imageId, url);
    return url;
  }

  /**
   * Revokes an object URL created by getObjectURL and cleans the interal object URL map.
   *
   * @param objectUrl The image's objectURL retrieved by calling getObjectUrl()
   * @param imageId The image's id, to which the objectURL belongs
   */
  private revokeObjectURL(imageId: string) {
    if (!this.objectUrls.has(imageId)) {
      // throw new Error('Object URL for imageID ' + imageId + ' not set!');
      return; // Omit if no object URL has been created (could be due to a failed request etc)
    }
    const objectUrl = this.objectUrls.get(imageId);
    this.objectUrls.delete(imageId);
    URL.revokeObjectURL(objectUrl);
  }

  private getIdentifier(imageId: number, imageSize: ImageSize): string {
    return imageSize + imageId;
  }
}
