import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, NavigationEnd } from '@angular/router';
import { of, concat, Observable } from 'rxjs';
import { filter, map, publishReplay, refCount } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RouteService {
  constructor(private router: Router) { }

  get cascadedData(): Observable<any> {
    return concat(
      of(this.router.routerState.snapshot.root),
      this.router.events
        .pipe(
          filter(event => event instanceof NavigationEnd),
          map(() => this.router.routerState.snapshot.root)
        )
    ).pipe(
      map((route: ActivatedRouteSnapshot) => {
        let cascadedData = {};

        let routeSnapshot = route;

        while (routeSnapshot) {
          if (!routeSnapshot.data) {
            continue;
          }

          cascadedData = Object.assign(cascadedData, routeSnapshot.data);

          routeSnapshot = routeSnapshot.firstChild;
        }

        return cascadedData;
      }),
      publishReplay(1),
      refCount()
    );

    /*

    .subscribe((route: ActivatedRouteSnapshot) => {
      let layout = false;
      let title = '';
      let background = false;

      let routeSnapshot = route;

      while (routeSnapshot.firstChild) {
        if (!routeSnapshot.data) {
          continue;
        }

        layout = layout || routeSnapshot.data.layout === 'two-toolbars';
        title = routeSnapshot.data.title || title;
        background = routeSnapshot.data.background !== undefined ? routeSnapshot.data.background : background;
        routeSnapshot = routeSnapshot.firstChild;
      }

      this.store.dispatch(new SetPageLayout(layout ? 'layout-two-toolbars' : 'layout-blank'));
      this.store.dispatch(new SetPageTitle(title || 'Laura & Ben\'s Hochzeit'));
      this.hasBackground = background;
    });*/

  }
}
