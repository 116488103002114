import { Component, OnInit, Input, EventEmitter, Output, OnDestroy } from '@angular/core';
import { Present } from '@wd/model';
import { Subject, combineLatest, Observable, ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isOwningPresent, getAmountOnPresent } from '../present.helpers';
import { DialogService } from '../../../service/dialog.service';

@Component({
  selector: 'wd-present-card',
  templateUrl: './present-card.component.html',
  styleUrls: ['./present-card.component.scss']
})
export class PresentCardComponent implements OnInit, OnDestroy {

  isUnavailable: boolean;

  isOwning: boolean;

  split_price: boolean;

  amount: number;

  initialAmount: number;

  @Input()
  set present(present: Present) {
    this._present$.next(present);
  }

  @Input()
  set invitationId(value: number) {
    this._invitationId$.next(value);
  }

  @Input()
  allowModifications = false;

  @Output()
  takeButtonClicked = new EventEmitter();

  @Output()
  giveButtonClicked = new EventEmitter();

  @Output()
  updateGiftValue = new EventEmitter<number>();

  get present$(): Observable<Present> {
    return this._present$.asObservable();
  }

  get invitationId$(): Observable<number> {
    return this._invitationId$.asObservable();
  }

  private _present$ = new ReplaySubject<Present>();
  private _invitationId$ = new ReplaySubject<number>();
  private _unsubscribeAll = new Subject<Present>();

  constructor(private dialogService: DialogService) { }

  ngOnInit() {
    combineLatest(
      this.present$,
      this.invitationId$
    ).pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe(
      ([present, invitationId]) => {
        this.isOwning = isOwningPresent(present, invitationId);
        this.isUnavailable = !(present.split_price || (present.invitation_id && this.isOwning) || !present.invitation_id);
        this.split_price = present.split_price;
        this.amount = getAmountOnPresent(present, invitationId);
        this.initialAmount = this.amount;
      }
    );
  }

  ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();

    this._present$.complete();
    this._invitationId$.complete();
  }

  openFullscreen(imageId: number) {
    this.dialogService.showImageDialog(imageId);
  }

}
