import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'wdSlice'
})
export class SlicePipe implements PipeTransform {
  transform(value: any[], startIndex: number, length: number): any {
    if (value && Array.isArray(value)) {
      return value.slice(startIndex, startIndex + length);
    } else {
      return [];
    }
  }
}
