import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDividerModule } from '@angular/material/divider';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatProgressBarModule } from '@angular/material/progress-bar';

import { FlexLayoutModule } from '@angular/flex-layout';

import { ImageDirective } from './image.directive';
import { LoadingButtonComponent } from './loading-button/loading-button.component';
import { StatusComponent } from './status/status.component';
import { LoadingComponent } from './loading/loading.component';
import { TodoStatusComponent } from './todo-status/todo-status.component';
import { GuestStatusComponent } from './guest-status/guest-status.component';
import { ResponsiveColumnsComponent } from './responsive-columns/responsive-columns.component';
import { ColumnPipe } from './column.pipe';
import { ImageGridComponent } from './image-grid/image-grid.component';
import { ImagePlaceholderComponent } from './image-placeholder/image-placeholder.component';
import { CardExplanationsComponent } from './card-explanations/card-explanations.component';
import { SlicePipe } from './slice.pipe';


const EXPORTS = [
  LoadingButtonComponent,
  ImageDirective,
  StatusComponent,
  LoadingComponent,
  TodoStatusComponent,
  GuestStatusComponent,
  ResponsiveColumnsComponent,
  ImageGridComponent,
  ImagePlaceholderComponent,
  CardExplanationsComponent,
  SlicePipe
];

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatTooltipModule,
    MatGridListModule,
    MatDividerModule,
    FlexLayoutModule,
    MatProgressBarModule
  ],
  declarations: [ ...EXPORTS, ColumnPipe ],
  exports: [ ...EXPORTS ]
})
export class ComponentsModule { }
