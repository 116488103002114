import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, PreloadingStrategy, Route } from '@angular/router';

import { AuthGuard, LoggedOutGuard, InvitationGuard } from '@wd/core/guards';
import { PageLayoutComponent, LayoutModule, PageLayoutRoute } from '@wd/core/layout';
import { Observable, of } from 'rxjs';

export class PreloadSelectedModulesList implements PreloadingStrategy {
  preload(route: Route, load: Function): Observable<any> {
    return route.data && route.data.preload ? load() : of(null);
  }
}

@NgModule({
  imports: [
    CommonModule,
    LayoutModule,
    RouterModule.forRoot(<PageLayoutRoute[]>[
      {
        path: '',
        component: PageLayoutComponent,
        data: null,
        children: [
          {
            path: 'admin',
            loadChildren: () => import('@wd/admin/admin.module').then(module => module.AdminModule)
          },
          {
            path: 'auth', loadChildren: () => import('@wd/login/login.module').then(module => module.LoginModule),
            canActivate: [LoggedOutGuard],
            data: {
              layout: 'blank',
              background: true
            }
          },
          {
            path: '',
            loadChildren: () => import('@wd/home/home.module').then(module => module.HomeModule),
            canActivate: [AuthGuard, InvitationGuard],
            data: {
              layout: 'two-toolbars'
            }
          }
        ]
      }
    ])
  ],
  declarations: [],
  exports: [RouterModule],
  providers: [AuthGuard]
})
export class AppRoutingModule { }
