import { Injectable } from '@angular/core';
import { State, Selector, StateContext, Action } from '@ngxs/store';

export class PageLayoutStateModel {
    layout: string;
    pageTitle: string;
}
​
export class SetPageLayout {
  static readonly type = '[PageLayout] Set layout';
  constructor(public layout: string) {}
}


export class SetPageTitle {
  static readonly type = '[PageLayout] Set page title';
  constructor(public title: string) {}
}

export class MenuButtonClicked {
  static readonly type = '[PageLayout] Menu button clicked';
  constructor(public id: string) {}
}

@State<PageLayoutStateModel>({
  name: 'pageLayout'
})
@Injectable()
export class PageLayoutState {
​
    @Selector([ PageLayoutState ])
    static title(state: PageLayoutStateModel) { return state.pageTitle; }
​
    @Selector([ PageLayoutState ])
    static pageLayout(state: PageLayoutStateModel) { return state.layout; }
​
    constructor() {}

    @Action(SetPageLayout)
    setPageLayout({ patchState }: StateContext<PageLayoutStateModel>, action: SetPageLayout) {
      patchState({
          layout: action.layout
      });
    }
​
    @Action(SetPageTitle)
    setPageTitle({ patchState }: StateContext<PageLayoutStateModel>, action: SetPageTitle) {
        patchState({
            pageTitle: action.title
        });
    }
}
