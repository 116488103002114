import {
  Directive,
  OnInit,
  OnDestroy,
  Input,
  Renderer2,
  TemplateRef,
  ViewContainerRef,
  EmbeddedViewRef,
  ChangeDetectorRef,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter
} from '@angular/core';
import { ImageService, ImageSize } from '../../service/image.service';
import { Subject, ReplaySubject, combineLatest, of } from 'rxjs';
import { switchMap, takeUntil, filter, tap, first, map, catchError } from 'rxjs/operators';
import { LazyTarget, LazyViewport } from './viewport';

import { saveAs } from 'file-saver';

enum ViewState {
  IMG,
  LOADING,
  ELSE,
  NONE
}

@Directive({
  selector: '[wdImage]',
  exportAs: 'wdImage'
})
export class ImageDirective implements OnInit, OnDestroy, OnChanges, LazyTarget {

  private viewState: ViewState = ViewState.NONE;

  /**
   * The ID of the image that should be loaded.
   */
  @Input()
  set wdImage(id: number) {
    this._imageId = id;
    this._imageId$.next(id);
  }

  /**
   * The size of the image.
   */
  @Input()
  set wdImageSize(size: ImageSize) {
    this._imageSize = size;
    this._imageSize$.next(size);
  }

  /**
   * Template that will be rendered as long as the image is being loaded or loading has finished.
   * Also shown, if the loading fails.
   */
  @Input()
  wdImageElse: TemplateRef<any>;

  /**
   * Template that will be rendered while loading.
   */
  @Input()
  wdImageLoading: TemplateRef<any>;

  @Output()
  imageDownload = new EventEmitter<boolean>();

  @Output()
  imageDownloadError = new EventEmitter<any>();

  get wdImage(): number {
    return this._imageId;
  }

  get wdImageSize(): ImageSize {
    return this._imageSize;
  }

  get element(): HTMLElement {
    return this._element;
  }

  set element(value: HTMLElement) {
    if (value !== this._element) {
      this.lazyViewport.removeTarget(value);
    }

    if (value && !this._wasVisible) {
      this.lazyViewport.addTarget(value, this);
    }

    this._element = value;
  }

  public isLoaded = false;

  private _element: HTMLElement;

  private _wasVisible = false;
  private _visibleChanged = new ReplaySubject<boolean>();
  private _unsubscribeAll = new Subject();

  /* ===== Keep track of internal data ===== */
  private _imageId: number;
  private _imageSize: ImageSize;
  private _imageId$ = new ReplaySubject<number>(1);
  private _imageSize$ = new ReplaySubject<ImageSize>(1);

  constructor(
    private lazyViewport: LazyViewport,
    private tmpl: TemplateRef<any>,
    private renderer2: Renderer2,
    private viewContainer: ViewContainerRef,
    private imageService: ImageService,
    private cdRef: ChangeDetectorRef
  ) {
  }

  ngOnInit(): void {
    this.showElse();

    combineLatest(this._imageId$, this._imageSize$, this._visibleChanged.pipe(filter(isVisible => isVisible), first())).pipe(
      takeUntil(this._unsubscribeAll),
      filter(([id, _, visible ]) => {
        if (id === null || id === undefined || !visible) {
          this.showElse(); return false;
        } else {
          return true;
        }
      }),
      tap(() => this.showLoading()),
      switchMap(([id, size]) => this.imageService.downloadImage(id, size))
    ).subscribe((imageObjectUrl) => {
      if (imageObjectUrl) {
        this.showImage();
        if (this.element) {
          if (this.isImageElement(this.element)) {
            const imgElement = this.element as HTMLImageElement;
            this.renderer2.setAttribute(imgElement, 'src', <string>imageObjectUrl);
            imgElement.onload = () => {
              const isPortrait = imgElement.width < imgElement.height;
              this.renderer2.addClass(this.element, isPortrait ? 'wd-image-portrait' : 'wd-image-landscape');
            };
          } else {
            this.renderer2.setStyle(this.element, 'background-image', 'url(' + imageObjectUrl + ')');
            this.renderer2.setStyle(this.element, 'background-size', 'cover');
            this.renderer2.setStyle(this.element, 'background-repeat', 'no-repeat');
            this.renderer2.setStyle(this.element, 'background-position', 'center');
          }
        }
      } else {
        this.showElse();
      }
    });

    if (!this.wdImageSize) {
      this.wdImageSize = ImageSize.THUMBNAIL;
    }

    if (!this.wdImageElse) {
      this.updateVisibility(true);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Every time the image size or image changes, release the old image!
    if (changes['wdImage'] || changes['wdImageSize']) {
      const previousImage = changes['wdImage'] ? changes['wdImage'].previousValue : this.wdImage;
      const previousImageSize = changes['wdImageSize'] ? changes['wdImageSize'].previousValue : this.wdImageSize;

      this.imageService.releaseImage(previousImage, previousImageSize);
    }
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
    if (this.element) {
      if (this.isImageElement(this.element)) {
        this.renderer2.removeAttribute(this.element, 'src');
      } else {
        this.renderer2.removeStyle(this.element, 'background-image');
        this.renderer2.removeStyle(this.element, 'background-size');
        this.renderer2.removeStyle(this.element, 'background-repeat');
        this.renderer2.removeStyle(this.element, 'background-position');
      }
    }
    this.imageService.releaseImage(this.wdImage, this.wdImageSize);
    this.lazyViewport.removeTarget(this.element);
  }

  downloadCurrentImage() {
    this.imageDownload.emit(true);
    this._imageId$.pipe(
      first(),
      switchMap((id) => this.imageService.meta(id).pipe(
        switchMap(meta => this.imageService.downloadImage(id, ImageSize.FULL).pipe(
          map((imageBlob) => ({ image: imageBlob, meta: meta }))
        ))
      )),
      catchError(() => of(null))
    ).subscribe((imageAndMeta) => {
      this.imageDownload.emit(false);
      if (imageAndMeta) {
        saveAs(imageAndMeta.image, (<any>imageAndMeta.meta).name);
      } else {
        this.imageDownloadError.emit();
      }
    });
  }

  private showElse() {
    this.changeTemplate(ViewState.ELSE, this.wdImageElse);
  }

  private showLoading() {
    // Check, if we even got a template for that (changeTemplate will handle the check); otherwise fall back to ELSE template
    if (!this.changeTemplate(ViewState.LOADING, this.wdImageLoading)) {
      this.showElse();
    }
  }

  private showImage() {
    const view = this.changeTemplate(ViewState.IMG, this.tmpl);

    if (!view) {
      return;
    }
  }

  private isImageElement(el: HTMLElement): el is HTMLImageElement {
    return el.tagName.toLowerCase() === 'img';
  }

  private changeTemplate(expectedState: ViewState, template: TemplateRef<any>): EmbeddedViewRef<any> {
    if (this.viewState !== expectedState && template) {
      this.viewContainer.clear();
      const result = this.viewContainer.createEmbeddedView(template);
      this.element = result.rootNodes[0] as HTMLElement;
      this.viewState = expectedState;

      this.cdRef.markForCheck();
      return result;
    } else {
      this.element = null;
      this.cdRef.markForCheck();
      return undefined;
    }
  }

  /**
   * Calles as soon as the current element becomes visible in the browser viewport.
   */
  public updateVisibility(isVisible: boolean): void {
    if (isVisible) {
      this._wasVisible = true;
      this._visibleChanged.next(true);
      this.lazyViewport.removeTarget(this.element);
    }
  }
}
