import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ScrollingModule } from '@angular/cdk/scrolling';

import { FlexLayoutModule } from '@angular/flex-layout';
import { YouTubePlayerModule } from '@angular/youtube-player';

import { ComponentsModule } from '../components';

import { HelpDialogComponent } from './help-dialog/help-dialog.component';
import { SimpleSnackBarComponent } from './simple-snackbar/simple-snackbar.component';
import { ImageDialogComponent } from './image-dialog/image-dialog.component';
import { YoutubeDialogComponent } from './youtube-dialog/youtube-dialog.component';


@NgModule({
  imports: [
    CommonModule,
    ComponentsModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    FlexLayoutModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    ScrollingModule,
    YouTubePlayerModule
  ],
  declarations: [
    HelpDialogComponent, ImageDialogComponent, SimpleSnackBarComponent, YoutubeDialogComponent
  ],
  entryComponents: [
    HelpDialogComponent, ImageDialogComponent, SimpleSnackBarComponent, YoutubeDialogComponent
  ]
})
export class DialogsModule { }
