import { Component, OnInit, Inject, ViewEncapsulation, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { SimpleSnackBarData, SnackBarType, SnackBarActionType } from './simple-snackbar.data';
import { Subject, timer, interval } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'wd-simple-snack-bar',
  templateUrl: './simple-snackbar.component.html',
  styleUrls: ['./simple-snackbar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimpleSnackBarComponent implements OnInit, OnDestroy {

  actionTypes = SnackBarActionType;
  remainingTime = 0;
  progressBarPercent = 100;
  private readonly autoCloseIntervalMs = 10;
  private _unsubscribeAll = new Subject();

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: SimpleSnackBarData,
    private matSnackbarRef: MatSnackBarRef<SimpleSnackBarComponent>,
    private cdRef: ChangeDetectorRef) {
      data.actionType = SimpleSnackBarComponent.getActionType(data.actionType, data.action);
      this.remainingTime = data.autoCloseDuration;
  }

  static getActionClass(action: SnackBarType): string[] {
    const snackbarContainerClass = ['wd-snackbar-container'];
    switch (action) {
      case SnackBarType.ERROR:
        snackbarContainerClass.push('error');
        break;
      case SnackBarType.SUCCESS:
        snackbarContainerClass.push('success');
      break;
      case SnackBarType.DEFAULT:
      default:
        break;
    }

    return snackbarContainerClass;
  }

  static getActionType(type: SnackBarActionType, action: string): SnackBarActionType {
    if (type === undefined) {
      return action ? SnackBarActionType.TEXT : SnackBarActionType.NONE;
    } else {
      return type;
    }
  }

  ngOnInit() {
    if (this.data.autoCloseDuration > 0) {
      interval(this.autoCloseIntervalMs).pipe(
        takeUntil(this._unsubscribeAll)
      ).subscribe(() => {
        this.remainingTime -= this.autoCloseIntervalMs;
        this.progressBarPercent = (this.remainingTime / this.data.autoCloseDuration) * 100;
        this.cdRef.markForCheck();
      });
    }
  }

  ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  dismiss() {
    this.matSnackbarRef.dismissWithAction();
  }

}
