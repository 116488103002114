import { Pipe, PipeTransform } from '@angular/core';
import { Present } from '@wd/model';

@Pipe({
  name: 'presentGiftSum'
})
export class PresentGiftSumPipe implements PipeTransform {

  transform(value: Present, allowExceedingValues?: boolean): any {
    if (value && value.present_gifts) {
      let sum = 0;
      value.present_gifts.forEach(x => {
        sum += x.amount;
      });

      if (allowExceedingValues === true) {
        return sum;
      } else {
        return sum > value.price ? value.price : sum;
      }
    } else {
      return 0;
    }
  }

}
