import { Component, OnInit, Input, TemplateRef, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { Subject, combineLatest, BehaviorSubject } from 'rxjs';
import { takeUntil, startWith } from 'rxjs/operators';

@Component({
  selector: 'wd-responsive-columns',
  templateUrl: './responsive-columns.component.html',
  styleUrls: ['./responsive-columns.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResponsiveColumnsComponent implements OnInit, OnDestroy {

  @Input()
  elements: any[];

  @Input()
  set maxElementsPerRow(value: number) {
    this.maxItems$.next(value);
  }

  @Input()
  elementTemplate: TemplateRef<any>;

  @Input()
  trackByFn: ((index, item) => number) = ((index, item) => index);

  columns = [1, 2, 3];

  private maxItems$ = new BehaviorSubject(3);
  private _unsubscribeAll = new Subject();

  constructor(private media: MediaObserver, private cdRef: ChangeDetectorRef) { }

  ngOnInit() {
    combineLatest(
      this.media.asObservable().pipe(startWith(null)),
      this.maxItems$.asObservable()
    ).pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe(([_, maxItems]) => {
      if (this.media.isActive('gt-sm')) {
        this.columns = [ 1, 2, 3 ];
      } else if (this.media.isActive('sm')) {
        this.columns = [ 1, 2 ];
      } else {
        this.columns = [ 1 ];
      }

      this.columns = this.columns.splice(0, maxItems);

      this.cdRef.markForCheck();
    });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

}
