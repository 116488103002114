import { Injectable } from '@angular/core';

export interface LazyTarget {
  updateVisibility: (isVisible: boolean) => void;
}

@Injectable({
  providedIn: 'root'
})
export class LazyViewport {
  private observer: IntersectionObserver;
  private targets: Map<Element, LazyTarget>;

  constructor() {
    this.observer = null;
    this.targets = new Map();

    this.setup();
  }

  public addTarget(element: Element, target: LazyTarget): void {
    if (this.observer) {
      this.targets.set(element, target);
      this.observer.observe(element);
    } else {
      // If we don't actually have an observer (lacking browser support), then we're
      // going to punt on the feature for now and just immediately tell the target
      // that it is visible on the page.
      target.updateVisibility(true);
    }

  }

  public hasTarget(element: Element): boolean {
    return this.targets.has(element);
  }

  public removeTarget(element: Element): void {
    // If the IntersectionObserver isn't supported, we never started tracking the
    // given target in the first place.
    if (this.observer && this.hasTarget(element)) {
      this.targets.delete(element);
      this.observer.unobserve(element);
    }
  }

  private handleIntersectionUpdate = (entries: IntersectionObserverEntry[]): void => {
    for (const entry of entries) {
      const lazyTarget = this.targets.get(entry.target);

      if (lazyTarget) {
        lazyTarget.updateVisibility(
          entry.isIntersecting
        );
      }
    }
  }

  // I setup the IntersectionObserver with the given element as the root.
  public setup(element: Element = null, offset: number = 0): void {
    // While the IntersectionObserver is supported in modern browsers, it will
    // never be added to Internet Explorer (IE) and is not in my version of Safari
    // (at the time of this post). As such, we'll only use it if it's available.
    // And, if it's not, we'll fall-back to non-lazy behaviors.
    try {
      this.observer = new IntersectionObserver(
        this.handleIntersectionUpdate,
        {
          root: element,
          rootMargin: `${offset}px`
        }
      );
    } catch (exception) {
      this.observer = null;
    }
  }

  public teardown(): void {
    if (this.observer) {
      this.observer.disconnect();
      this.observer = null;
    }

    this.targets.clear();
    this.targets = null;
  }
}
