import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store, Actions, ofActionSuccessful, ofActionErrored } from '@ngxs/store';
import { InvitationState, LoadInvitation } from '@wd/core/state';
import { Observable, merge } from 'rxjs';
import { first, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InvitationGuard implements CanActivate {
  constructor(private store: Store, private actions: Actions, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    if (this.store.selectSnapshot(InvitationState.invitation)) {
      return true;
    }

    const nextLoadActionResult = merge(
      this.actions.pipe(ofActionSuccessful(LoadInvitation)),
      this.actions.pipe(ofActionErrored(LoadInvitation), map(() => null))
    ).pipe(
      first(),
      map(() => true)
    );

    this.store.dispatch(new LoadInvitation());

    return nextLoadActionResult;
  }
}
