export enum TodoType {
    GUESTS = 'guest',
    PRESENT = 'present',
    HOTEL = 'hotel',
    PROFILE = 'profile',
    FOOD = 'food'
}

export interface Todo {
    type: TodoType;
    id: string;
    description: string;
    status: 0 | 1;
}
