import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';

@Component({
  selector: 'wd-loading-button',
  templateUrl: './loading-button.component.html',
  styleUrls: ['./loading-button.component.scss']
})
export class LoadingButtonComponent implements OnInit {

  @Input()
  loading: boolean = false;

  @Input()
  disabled: boolean = false;

  @Input()
  buttonType: string = null;

  @Input()
  color: string = 'primary';

  @Output()
  buttonClicked = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

}
