import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { ComponentsModule } from '../components';

import { PresentGridComponent } from './present-grid/present-grid.component';
import { PresentCardComponent } from './present-card/present-card.component';
import { PresentGiftSumPipe } from './present-gift-sum.pipe';

const EXPORTS = [
  PresentCardComponent,
  PresentGridComponent
];

@NgModule({
  imports: [
    CommonModule,
    ComponentsModule,
    FormsModule,
    MatCardModule,
    MatInputModule,
    MatIconModule,
    MatDividerModule,
    MatButtonModule,
    FlexLayoutModule
  ],
  declarations: [ ...EXPORTS, PresentGiftSumPipe ],
  exports: [ ...EXPORTS ]
})
export class PresentsModule { }
