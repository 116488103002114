import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import {
  animate,
  AnimationBuilder,
  AnimationPlayer,
  style
} from '@angular/animations';
import { Router } from '@angular/router';
import { timer } from 'rxjs';

import { ImageLoaderService } from './image-loader.service';

@Injectable({
  providedIn: 'root'
})
export class PageLoaderService {
  splashScreenEl: any;
  player: AnimationPlayer;

  constructor(
    private _animationBuilder: AnimationBuilder,
    private imageLoaderService: ImageLoaderService,
    @Inject(DOCUMENT) private _document: any,
    private _router: Router
  ) {
    this._init();
  }

  private _init(): void {
    // Get the splash screen element
    this.splashScreenEl = this._document.body.querySelector(
      '#wd-page-loader'
    );
    // Get the splash screen element
    const rootEl = this._document.body.querySelector(
      'wd-root'
    );

    if (rootEl) {
      setTimeout(() => (<HTMLElement>rootEl).classList.add('loaded'), 0);
    }

    // If the splash screen element exists...
    if (this.splashScreenEl) {

      const showWhenImagesLoading = this.imageLoaderService.loading
      .subscribe((isLoading) => {
        if (isLoading) {
          this.show();
        } else {
          timer(800).subscribe(() => this.hide());
        }
      });
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Show the splash screen
   */
  show(): void {
    this.player = this._animationBuilder
      .build([
        style({
          opacity: '1',
          zIndex: '999'
        })
      ])
      .create(this.splashScreenEl);

    this.player.play();
  }

  /**
   * Hide the splash screen
   */
  hide(): void {
    this.player = this._animationBuilder
      .build([
        style({ opacity: '1' }),
        animate(
          '200ms ease',
          style({
            opacity: '0',
            zIndex: '-10'
          })
        )
      ])
      .create(this.splashScreenEl);

    setTimeout(() => {
      this.player.play();
    }, 0);
  }
}
