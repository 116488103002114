import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'wd-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StatusComponent implements OnInit {

  @Input()
  status = 0;

  @Input()
  text: string = null;

  @Input()
  icon: string = null;

  @Input()
  inverse = false;

  @Input()
  successVal: number | boolean = 1;

  @Input()
  errorVal: number | boolean = -1;

  @Input()
  unknownVal: number | boolean = 0;

  @Input()
  successText = 'Nimmt teil';

  @Input()
  errorText = 'Nimmt nicht teil';

  @Input()
  unknownText = 'Angabe fehlt noch';


  constructor() { }

  ngOnInit() {
  }

}
