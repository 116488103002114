import {
  Component, OnInit, Inject, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild, AfterViewInit, ViewEncapsulation, HostListener
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { Subject, timer } from 'rxjs';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { LEFT_ARROW, RIGHT_ARROW } from '@angular/cdk/keycodes';
import { ImageSize } from '@wd/core/service/image.service';

export interface ImageDialogConfig {
  selectedImageId?: number;
  imageIds: number[];
  showDownloader?: boolean;
  displayDownloadErrorMessage: () => void;
}

@Component({
  selector: 'wd-image-dialog',
  templateUrl: './image-dialog.component.html',
  styleUrls: ['./image-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ImageDialogComponent implements OnInit, AfterViewInit {

  imageIds: number[];

  showDownloader = false;
  downloadInProgress = false;

  imageSize = ImageSize;

  private errorMessageHandler: () => void;

  private scale = 1;
  private transform: { x: number, y: number } = { x: 0, y: 0 };
  private lastTransform: { x: number, y: number };
  private lastMoveStart: { x: number, y: number };
  private lastScale: number = this.scale;

  get currentIndex(): number {
    return this._currentIndex;
  }

  set currentIndex(value: number) {
    this._currentIndex = value;
    this.resetTransform();
  }

  get isTransformed(): boolean {
    return this.scale !== 1;
  }

  get currentImageId(): number {
    return this.imageIds ? this.imageIds[this.currentIndex || 0] : null;
  }

  private _currentIndex = 0;
  private resizeSubject = new Subject();
  private _unsubscribeAll = new Subject();

  @ViewChild(CdkVirtualScrollViewport)
  private scrollViewPort: CdkVirtualScrollViewport;

  @HostListener('window:resize', ['$event'])
  windowResized() {
    this.resizeSubject.next();
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) config: ImageDialogConfig,
    private cdRef: ChangeDetectorRef,
    private sanitizer: DomSanitizer
  ) {
    this.currentIndex = config.selectedImageId !== undefined ? config.imageIds.indexOf(config.selectedImageId) : 0;
    this.imageIds = config.imageIds;
    this.errorMessageHandler = config.displayDownloadErrorMessage;
    this.showDownloader = config.showDownloader || false;
  }

  ngOnInit() {
    this.resizeSubject.pipe(
      takeUntil(this._unsubscribeAll),
      debounceTime(50)
    ).subscribe(() => this.scrollViewPort.checkViewportSize());
  }

  ngAfterViewInit() {
    // On start scroll with auto -> otherwise ALL images before the selected one
    // get rendered through the smooth animation and will triggered for loading ...
    timer(0).subscribe(() => this.scrollToSelection('auto'));
  }

  scrollToSelection(behavior: ScrollBehavior = 'smooth') {
    const elementWidth = 140;
    const size = this.scrollViewPort.getViewportSize();
    const maxSize = this.scrollViewPort.getDataLength() * elementWidth;
    const offset = this.currentIndex * elementWidth - size / 2 + 70;
    this.scrollViewPort.scrollToOffset(Math.min(Math.max(offset, 0), maxSize), behavior);
  }

  selectIndex(index: number) {
    this.currentIndex = index;
    this.scrollToSelection();
    this.cdRef.markForCheck();
  }

  previous() {
    if (this.isTransformed) {
      return;
    }

    if (this.currentIndex > 0) {
      this.currentIndex--;
      this.scrollToSelection();
    }
  }

  next() {
    if (this.isTransformed) {
      return;
    }

    if (this.currentIndex < this.imageIds.length - 1) {
      this.currentIndex++;
      this.scrollToSelection();
    }
  }

  onPinchStart($event) {
    this.lastScale = this.scale;
  }

  onMoveStart($event) {
    // Save values for recalculation
    this.lastMoveStart = $event.center;
    this.lastTransform = {
      x: this.transform.x,
      y: this.transform.y
    };
  }

  onPinch($event) {
    // Recalculate current scale
    this.scale = this.lastScale * $event.scale;
    this.cdRef.markForCheck();
  }

  onMove($event) {
    // Only allow movement on scaled images
    if (!this.isTransformed) {
      return;
    }

    this.transform = {
      x: this.lastTransform.x + (this.lastMoveStart.x - $event.center.x) / this.scale,
      y: this.lastTransform.y + (this.lastMoveStart.y - $event.center.y) / this.scale
    };

    this.cdRef.markForCheck();
  }

  resetTransform() {
    this.scale = 1;
    this.transform = { x: 0, y: 0};
  }

  getTransformValue() {
    return this.sanitizer.bypassSecurityTrustStyle(
      `scale(${this.scale}) translate(${(-1 * this.transform.x)}px, ${(-1 * this.transform.y)}px)`
    );
  }

  showDownloadError() {
    if (this.errorMessageHandler) {
      this.errorMessageHandler();
    }
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    // tslint:disable-next-line:deprecation
    const code = event.keyCode;
    if (code === LEFT_ARROW) {
      this.previous();
      event.stopPropagation();
    } else if (code === RIGHT_ARROW) {
      this.next();
      event.stopPropagation();
    }
  }
}
