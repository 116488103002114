import { Component, OnInit, HostBinding, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngxs/store';

import { SetPageLayout, SetPageTitle, MenuButtonClicked } from '@wd/core/state';
import { DialogService, RouteService } from '@wd/core/service';
import { MediaObserver } from '@angular/flex-layout';

@Component({
  selector: 'wd-page-layout',
  templateUrl: './page-layout.component.html',
  styleUrls: ['./page-layout.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PageLayoutComponent implements OnInit {

  topMenuItems = [];
  footerMenuItems = [];

  @HostBinding('class.layout-has-background')
  hasBackground = false;

  showAdditionalMenuItems = false;

  pageTitle = 'Laura & Ben\'s Hochzeit';

  constructor(
    private routeService: RouteService,
    private store: Store,
    private dialogService: DialogService,
    private media: MediaObserver) {
  }

  ngOnInit() {
    this.media.asObservable().subscribe((_) => {
      this.showAdditionalMenuItems = this.media.isActive('lt-sm');
    });

    this.routeService.cascadedData.subscribe(data => {
      const layout = data.layout === 'two-toolbars';
      const title = data.pageTitle || data.title;
      const background = data.background !== undefined;

      this.store.dispatch(new SetPageLayout(layout ? 'layout-two-toolbars' : 'layout-blank'));
      this.store.dispatch(new SetPageTitle((title ? title + ' · ' + this.pageTitle : undefined) || this.pageTitle));
      this.hasBackground = background;

      this.topMenuItems = data.topMenuItems || [];
      this.footerMenuItems = data.footerMenuItems || [];

    });
  }

  handleMenuClick(menuItem) {
    if (menuItem.id === 'show_info') {
      this.dialogService.showHelpDialog();
    } else {
      this.store.dispatch(new MenuButtonClicked(menuItem.id));
    }
  }

}
