<div class="menu-browser" fxLayout="row" fxLayoutAlign="center center" fxShow [fxHide.lt-sm]="mobileMenu">
  <div class="menu-browser-item" fxLayout="row" fxLayoutAlign="start center" [style.display]="i >= exceedingItemIndex ? 'none' : null" *ngFor="let item of menuItems; let l = last; let i = index">
    <button mat-button [routerLink]="item.path" [routerLinkActive]="['active']"  [routerLinkActiveOptions]="{exact: item.exact}" *ngIf="item.path; else clickableMenuButton">
        <mat-icon>{{ item.icon }}</mat-icon> {{ item.title }}
    </button>
    <ng-template #clickableMenuButton>
      <button mat-button (click)="emitItemClick(item)">
          <mat-icon>{{ item.icon }}</mat-icon> {{ item.title }}
      </button>
    </ng-template>
    <span class="bullet" *ngIf="!l">&bull;</span>
  </div>
  <button class="menu-browser-item-toggler" [style.display]="exceedingItemIndex < menuItems.length ? null : 'none'" mat-button [matMenuTriggerFor]="exceedingMenu"><mat-icon>menu</mat-icon> Menü</button>
</div>

<mat-menu #appMenu="matMenu">
  <ng-container *ngTemplateOutlet="menuTemplate; context: {$implicit: showAdditionalMobileMenuItems ? menuItems.concat(mobileMenuItemsAdditional) : menuItems}"></ng-container>
</mat-menu>

<mat-menu #exceedingMenu="matMenu">
  <ng-container *ngTemplateOutlet="menuTemplate; context: {$implicit: (menuItems | wdSlice:exceedingItemIndex:(menuItems.length - exceedingItemIndex)) }"></ng-container>
</mat-menu>

<div class="menu-phone" fxLayout="row" fxLayoutAlign="center center" fxHide [fxShow.lt-sm]="mobileMenu">
  <button mat-button [matMenuTriggerFor]="appMenu">
    <mat-icon>menu</mat-icon> Menü öffnen
  </button>
</div>


<ng-template #menuTemplate let-items>
  <ng-container *ngFor="let item of items">
    <button mat-menu-item [routerLink]="item.path" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact: item.exact}" *ngIf="item.path; else clickableMenuItem">
        <mat-icon>{{ item.icon }}</mat-icon> {{ item.title }}
    </button>

    <ng-template #clickableMenuItem>
      <button mat-menu-item (click)="emitItemClick(item)">
          <mat-icon>{{ item.icon }}</mat-icon> {{ item.title }}
      </button>
    </ng-template>
  </ng-container>
</ng-template>
