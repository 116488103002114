import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsStoragePluginModule, StorageOption } from '@ngxs/storage-plugin';

import { AuthState, PageLayoutState, InvitationState, SettingsState } from '@wd/core/state';

import { AppComponent } from '@wd/app.component';
import { AppRoutingModule } from '@wd/app-routing.module';
import { CoreModule } from './core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { environment } from '@environment';

import { HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    MatSnackBarModule,
    NgxsModule.forRoot([
      AuthState,
      PageLayoutState,
      SettingsState,
      InvitationState
    ], { developmentMode: !environment.production }),
    CoreModule.forRoot(),
    NgxsRouterPluginModule.forRoot(),
    NgxsStoragePluginModule.forRoot({
      key: [ 'auth.token', 'adminauth.token' ],
      storage: StorageOption.SessionStorage
    })
  ],
  bootstrap: [ AppComponent ]
})
export class AppModule { }
