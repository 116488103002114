export enum SupportedSettings {
  ENABLE_PRESENTS = 'enable_presents',
  LOCK_PRESENTS = 'lock_presents',
  ENABLE_HOTEL = 'enable_hotel',
  LOCK_HOTEL = 'lock_hotel',
  ENABLE_MENU = 'enable_menu',
  ENABLE_FOOD_CONTRIBUTION = 'enable_food_contribution',
  LOCK_FOOD_CONTRIBUTION = 'lock_food_contribution',
  LOCK_PARTICIPATION_STATUS = 'lock_participation_status',
  LOCK_HOTEL_CONFIRMATION = 'lock_hotel_confirmation',
  LOCK_SHUTTLE_SERVICE = 'lock_shuttle_service',
  LOCK_PROFILES = 'lock_profiles',
  LOCK_BREAKFAST = 'lock_breakfast',
  ENABLE_PHOTOS = 'enable_photos'
}

export interface Setting {
  key: SupportedSettings;
  value: boolean | string;
}
