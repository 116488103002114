import { Injectable } from '@angular/core';
import { State, Selector, StateContext, Action } from '@ngxs/store';
import { Setting, SupportedSettings } from '@wd/model';

export class SettingsStateModel {
  settings?: Setting[];
}
​
export class UpdateSettings {
  static readonly type = '[Settings] update';
  constructor(public settings: Setting[] ) {}
}

@State<SettingsStateModel>({
  name: 'settings',
  defaults: {
    settings: []
  }
})
@Injectable()
export class SettingsState {
  ​
  @Selector([ SettingsState ])
  static settings(state: SettingsStateModel): Setting[] { return state.settings; }
  ​
  @Selector([ SettingsState ])
  static enablePresents(state: SettingsStateModel): boolean {
    return SettingsState.getSetting(SupportedSettings.ENABLE_PRESENTS, state.settings) as boolean;
  }
  ​
  @Selector([ SettingsState ])
  static enableHotel(state: SettingsStateModel): boolean {
    return SettingsState.getSetting(SupportedSettings.ENABLE_HOTEL, state.settings) as boolean;
  }
  ​
  @Selector([ SettingsState ])
  static enableMenu(state: SettingsStateModel): boolean {
    return SettingsState.getSetting(SupportedSettings.ENABLE_MENU, state.settings) as boolean;
  }
  ​
  @Selector([ SettingsState ])
  static enableFoodContribution(state: SettingsStateModel): boolean {
    return SettingsState.getSetting(SupportedSettings.ENABLE_FOOD_CONTRIBUTION, state.settings) as boolean;
  }
  ​
  @Selector([ SettingsState ])
  static lockFoodContribution(state: SettingsStateModel): boolean {
    return SettingsState.getSetting(SupportedSettings.LOCK_FOOD_CONTRIBUTION, state.settings) as boolean;
  }
  ​
  @Selector([ SettingsState ])
  static lockHotel(state: SettingsStateModel): boolean {
    return SettingsState.getSetting(SupportedSettings.LOCK_HOTEL, state.settings) as boolean;
  }
  ​
  @Selector([ SettingsState ])
  static lockPresents(state: SettingsStateModel): boolean {
    return SettingsState.getSetting(SupportedSettings.LOCK_PRESENTS, state.settings) as boolean;
  }

  @Selector([ SettingsState ])
  static lockHotelConfirmation(state: SettingsStateModel): boolean {
    return SettingsState.getSetting(SupportedSettings.LOCK_HOTEL_CONFIRMATION, state.settings) as boolean;
  }
  ​
  @Selector([ SettingsState ])
  static lockParticipationStatus(state: SettingsStateModel): boolean {
    return SettingsState.getSetting(SupportedSettings.LOCK_PARTICIPATION_STATUS, state.settings) as boolean;
  }

  @Selector([ SettingsState ])
  static lockShuttleService(state: SettingsStateModel): boolean {
    return SettingsState.getSetting(SupportedSettings.LOCK_SHUTTLE_SERVICE, state.settings) as boolean;
  }

  @Selector([ SettingsState ])
  static lockProfiles(state: SettingsStateModel): boolean {
    return SettingsState.getSetting(SupportedSettings.LOCK_PROFILES, state.settings) as boolean;
  }

  @Selector([ SettingsState ])
  static lockBreakfast(state: SettingsStateModel): boolean {
    return SettingsState.getSetting(SupportedSettings.LOCK_BREAKFAST, state.settings) as boolean;
  }

  @Selector([ SettingsState ])
  static enablePhotos(state: SettingsStateModel): boolean {
    return SettingsState.getSetting(SupportedSettings.ENABLE_PHOTOS, state.settings) as boolean;
  }

  private static getSetting(setting: SupportedSettings, settings: Setting[]): boolean | string {
    if (!settings) {
      return null;
    }

    return settings.find(s => s.key === setting).value;
  }
    ​
  @Action(UpdateSettings)
  login({ patchState }: StateContext<SettingsStateModel>, action: UpdateSettings) {
    patchState({ settings: action.settings });
  }
​
}
