<mat-card class="present-card mat-z-10" *ngIf="present$ | async; let present" [class.taken-readonly]="!allowModifications && (isUnavailable || (present | presentGiftSum:true) > 0)" [class.taken]="allowModifications && isUnavailable" [class.owning]="isOwning">
  <div class="present-card-overlay"></div>
    <mat-card-header>
      <mat-card-title [class.text-primary]="isOwning">{{ present.name }}</mat-card-title>
      <mat-card-subtitle><ng-container *ngIf="!split_price; else splitSubtitle"><ng-container *ngIf="isOwning">Dein Beitrag: </ng-container><span [class.text-primary]="isOwning">{{ present.price }}€</span></ng-container></mat-card-subtitle>
      <ng-template #splitSubtitle>
          Gesamt {{ present | presentGiftSum:true }}€ von {{ present.price <= 0 ? '&#8734;' : present.price + '€'}} beigetragen
          <ng-container *ngIf="initialAmount"><br />Dein Beitrag: <span class="text-primary">{{ initialAmount }} €</span></ng-container>
        </ng-template>
    </mat-card-header>
    <div class="intro-image" *wdImage="present.image?.id || present.image_id; else defaultImage; else empty" (click)="openFullscreen(present.image_id)"></div>
    <ng-template #defaultImage>
      <wd-image-placeholder class="intro-image"></wd-image-placeholder>
    </ng-template>
    <ng-template #empty></ng-template>
    <mat-card-content>
      <p>
        {{ present.description }}
      </p>

      <p *ngIf="isUnavailable">Dieses Geschenk wurde bereits von einem anderen Gast gewählt.</p>
    </mat-card-content>
    <ng-container *ngIf="allowModifications">
        <ng-container *ngIf="split_price; else normal">
            <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
              <mat-form-field fxFlex="1 1 auto" appearance="outline">
                <mat-label>Dein Beitrag</mat-label>
                <input matInput [(ngModel)]="amount" type="number" placeholder="50">
                <mat-icon matPrefix>euro</mat-icon>
              </mat-form-field>
              <div fxFlex="0 1 auto">
                <button mat-button (click)="updateGiftValue.emit(amount || 0)">Speichern</button>
              </div>
            </div>
          </ng-container>
          <ng-template #normal>
            <ng-container *ngIf="!isUnavailable">
              <mat-divider></mat-divider>
              <mat-card-actions>
                <button *ngIf="!isOwning" mat-button (click)="takeButtonClicked.emit()"><mat-icon>add_circle_outline</mat-icon> BEITRAGEN</button>
                <button *ngIf="isOwning" mat-button (click)="giveButtonClicked.emit()"><mat-icon>remove_circle_outline</mat-icon> ZURÜCKLEGEN</button>
              </mat-card-actions>
            </ng-container>
          </ng-template>
    </ng-container>
  </mat-card>
