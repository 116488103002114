import { Component, OnInit, Input, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { Subject, concat, of } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'wd-image-grid',
  templateUrl: './image-grid.component.html',
  styleUrls: ['./image-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageGridComponent implements OnInit, OnDestroy {

  columns = 5;

  @Input()
  imageIds: number[];

  @Output()
  imageSelected = new EventEmitter<number>();

  private _unsubscribeAll = new Subject();

  constructor(private media: MediaObserver, private cdRef: ChangeDetectorRef) { }

  ngOnInit() {
    concat(
      of(null),
      this.media.asObservable()
    ).pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe((mediaChange) => {
      if (this.media.isActive('gt-md')) {
        this.columns = 5;
      } else if (this.media.isActive('md')) {
        this.columns = 4;
      } else if (this.media.isActive('sm')) {
        this.columns = 3;
      } else {
        this.columns = 2;
      }

      this.cdRef.markForCheck();
    });
  }

  ngOnDestroy() {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
